<template>

  <div class="v-school-list">
        <vi-header v-if="$route.path == '/' || $route.path == '/search-schools'"/>

    <main v-if="!viLoader">
      <div v-if="filteredSchool">
      <div class="dispflex brdcrumbscont text-center pl-3 pr-3" style="background: #E1E1E1;">
        <div>
          <ul class="breadcrumb">
            <li>
              <a href="#"
                ><i class="fas fa-home" @click="redirectRoute('/')"></i
              ></a>
            </li>
            <li>
              <a>School List</a>
            </li>
            <!-- <li>School View</li> -->
          </ul>
        </div>
        <h4 v-if="filteredSchool.length > 0">
          There are {{ filteredSchool.length }} Schools found.
        </h4>
        <div class="dispflex">
          <label class="mr-2 badge badge-success">
            <i class="fas fa-filter"></i>
          </label>
          <b-dropdown
            id="boardType"
            right
            text="Board Type"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            data-toggle-second="tooltip"
            data-placement="left"
            title="Board Type"
            style="margin-right:3px"
          >
            <b-dropdown-text class="web-chkcont w-100">
              <ul>
                <li v-for="(boardType, index) in boardTypeArray" :key="index">
                  <label class="web-custcheckbox"
                    >{{ boardType }}
                    <input
                      type="checkbox"
                      v-model="checkedBoardType"
                      :value="boardType"
                    />
                    <span class="web-checkmarkchk"></span>
                  </label>
                </li>
              </ul>
            </b-dropdown-text>
          </b-dropdown>
          <b-dropdown
            id="schoolType"
            right
            text="School Type"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            data-toggle-second="tooltip"
            data-placement="right"
            title="School Type"
            style="margin-right:3px"
          >
            <b-dropdown-text class="web-chkcont w-100">
              <ul>
                <li v-for="(schoolType, index) in schoolTypeArray" :key="index">
                  <label class="web-custcheckbox"
                    >{{ schoolType }}
                    <input
                      type="checkbox"
                      v-model="checkedschoolType"
                      :value="schoolType"
                    />
                    <span class="web-checkmarkchk"></span>
                  </label>
                </li>
              </ul>
            </b-dropdown-text>
          </b-dropdown>
          <b-dropdown
            id="mediumType"
            right
            text="Medium"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            data-toggle-second="tooltip"
            data-placement="right"
            title="Medium Type"
            style="margin-right:3px"
          >
            <b-dropdown-text class="web-chkcont w-100">
              <ul>
                <li v-for="(medium, index) in mediumList" :key="index">
                  <label class="web-custcheckbox"
                    >{{ medium }}
                    <input
                      type="checkbox"
                      v-model="checkedMediumType"
                      :value="medium"
                    />
                    <span class="web-checkmarkchk"></span>
                  </label>
                </li>
              </ul>
            </b-dropdown-text>
          </b-dropdown>
          <b-dropdown
            id="facilitiesType"
            right
            text="Facilities"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            data-toggle-second="tooltip"
            data-placement="right"
            title="Facilities Type"
            style="margin-right:3px"
          >
            <b-dropdown-text class="web-chkcont w-100">
              <ul>
                <li v-for="(facilities, index) in facilitiesList" :key="index">
                  <label class="web-custcheckbox"
                    >{{ facilities }}
                    <input
                      type="checkbox"
                      v-model="checkedFacilities"
                      :value="facilities"
                    />
                    <span class="web-checkmarkchk"></span>
                  </label>
                </li>
              </ul>
            </b-dropdown-text>
          </b-dropdown>

          <b-dropdown
            id="facilitiesType"
            right
            text="Class"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            data-toggle-second="tooltip"
            data-placement="right"
            title="Class"
            style="margin-right:3px"
          >
            <b-dropdown-text class="web-chkcont w-100">
              <ul>
                <li v-for="(fromClass, index) in classList" :key="index">
                  <label class="web-custcheckbox"
                    >{{ fromClass }}
                    <input
                      type="checkbox"
                      v-model="checkedClass"
                      :value="fromClass"
                    />
                    <span class="web-checkmarkchk"></span>
                  </label>
                </li>
              </ul>
            </b-dropdown-text>
          </b-dropdown>
          
        </div>
      </div>
      <div class="web-innerpagecont py-3 pt-3">
        <div class="container-fluid">
          <div class="row web-row-flex">
            <div class="col-md-2 left-sidebar">
              <div class="lftFiltercont" style="background: #E1E1E1;">
                <form id="lftFilter">
                  <p style="padding:4px 6px;margin:0">
                    <strong>FILTER MY SEARCH</strong>
                  </p>
                  <div style="padding:4px 6px;margin:0">
                    <label style="margin:0;font-weight:600">States</label>
                    <input
                      type="text"
                      name="stateName"
                      v-model="searchWords"
                      class="form-control form-input"
                      id="myInput"
                      placeholder="Enter State Name"
                      required="required"
                      @input="fetchSearchedStates"
                    />
                  </div>
                  <div class="web-chkcont">
                    <ul id="myUL">
                      <li v-for="(item, index) in stateList" :key="index">
                        <label class="web-custcheckbox">
                          {{ item.state_name }}
                          <input
                            type="checkbox"
                            v-model="item.isSelected"
                            :value="item.state_name"
                            @change="
                              getDistrictList(item.state_name, item.isSelected)
                            "
                          />
                          <span class="web-checkmarkchk"></span>
                        </label>
                      </li>
                    </ul>
                  </div>

                  <div style="padding:4px 6px;margin-top:20px">
                    <label style="margin:0;font-weight:600">Cities </label>
                    <input
                      type="text"
                      name="districtName"
                      value=""
                      class="form-control form-input"
                      id="cityId"
                      placeholder="Enter City Name"
                      required="required"
                      @input="fetchSearchedCities"
                    />
                  </div>
                  <div class="web-chkcont">
                    <ul id="cityUL">
                      <li v-for="(item, index) in allCityList" :key="index">
                        <label class="web-custcheckbox">
                          {{ item.city_name }}
                          <input
                            type="checkbox"
                            v-model="checkedDistrict"
                            :value="item.city_name"
                          />
                          <span class="web-checkmarkchk"></span>
                        </label>
                      </li>
                    </ul>
                  </div>
                </form>
              </div>
            </div>
            <div
              class="col-md-10 schlist"
              id="schlist"
              v-if="filteredSchool.length > 0"
            >
              <div
                id="schlistcont"
                v-for="(item, index) in filteredSchool.slice(
                  perPage * (currentPage - 1),
                  perPage * currentPage
                )"
                :key="index"
              >
                <div class="web-whitebg mb-5 web-dispflex wow web-fadeInUp">
                  <div class="web-left_item">
                    <div class="web-shcimg">
                      <img
                        v-if="item.image && item.image.path"
                        :src="item.image.path"
                        :alt="item.accountName"
                        :title="item.accountName"
                      />
                      <img
                        v-else
                        src="../../assets/img/noimage.png"
                        alt="Avatar"
                      />
                    </div>
                    <a
                      href="javascript:void(0);"
                      class="web-orgbtn"
                      v-if="item.isAdmissionAvailable"
                      >Admission Open Now</a
                    >
                    <div class="web-verified" v-if="item.isVerified">
                      <img
                        src="../../assets/img/verified.png"
                        alt="Vidhyaan web-verified"
                        title="web-verified"
                      />
                    </div>
                  </div>
                  <div class="web-right_item wow">
                    <div class="web-dispflex mb-2">
                      <div>
                        <h4>{{ item.accountName }}</h4>
                        <p>
                          {{ item.street ? item.street : '' }},{{ item.city ? item.city : '' }},{{ item.district ? item.district : '' }},{{ item.state ? item.state : '' }},{{ item.pincode ? item.pincode : '' }}

                          <!-- {{ item.state | capitalize }}
                          <span v-if="item.area"> | </span> -->
                          <span
                            ><a
                              href="javascript:void(0);"
                              @click.prevent="
                                viewMap(item.latitude, item.longitude)
                              "
                            >
                              <v-icon name="map-marker-alt" /> View on Map</a
                            ></span
                          >
                        </p>
                      </div>

                      <div>
                        <div>
                        <p>School Type</p>
                        <span>{{ item.schoolType }}</span>
                      </div>
                      </div>

                        <div>
                       <div>
                        <p>School Classification</p>
                        <span>{{ item.classification }}</span>
                      </div>
                      </div>

                    </div>
                  <div class="web-dispflex mb-2">
                        <p>Board :</p>
                        <span>{{ item.affilliateIdToBoard | capitalize }}</span>
                      
                    </div>
                      <div class="web-dispflex mb-2">
                     
                        <p>Medium :</p>
                        <span>{{ item.medium | capitalize }}</span>
                     
                    
                    </div>
                      <div class="web-dispflex mb-2">
                      
                       
                        <p>Website :</p>
                        <span
                          ><a :href="item.website" target="_blank">{{
                            item.website | removeURLPrefix
                          }}</a></span
                        >
                     
                      <!--<div>
										<button type="submit" class="web-custbutton" >View School</button>
									</div>-->
                    </div>

                    <div class="web-dispflex mb-2 web-faci mb-4">
                      <div v-if="item.facilities && item.facilities.length > 0">
                        <p>Facilities</p>
                        <ul>
                          <li
                            v-for="(fitem, i) in item.facilities"
                            :key="i"
                            class="web-tooltipt"
                          >
                            <v-icon :name="fitem.icon"></v-icon
                            ><span class="web-tooltiptext">{{
                              fitem.name | capitalize
                            }}</span>
                          </li>
                        </ul>
                      </div>
                      <div v-if="item.lowestFees">
                        <p>Starting Fees</p>
                        <span class="fees"
                          ><strong>Rs.{{ item.lowestFees }}</strong></span
                        >
                      </div>
                    </div>
                  </div>
                  <!--<div class="web-enrollbtn "><a href="javascript:void(0);" class="mr-3">Enroll your Kid</a> | <a href="javascript:void(0);" class="ml-3">View School</a></div>-->
                  <div
                    class="btn-group web-enrollbtn wow web-fadeIn"
                    role="group"
                    aria-label="Basic example"
                  >
                    <button
                      type="button"
                      class="btn btn-secondary btngroupleft"
                    >
                      Enroll your Kid
                    </button>
                    <button
                      type="button"
                      class="btn btn-secondary btngroupright"
                      @click.prevent="viewSchoolAction(item)"
                    >
                      View School
                    </button>
                  </div>
                </div>
              </div>
              <div class="btmpagination">
                <b-pagination
                  v-if="filteredSchool.length > perPage"
                  v-model="currentPage"
                  :total-rows="filteredSchool.length"
                  :per-page="perPage"
                  aria-controls="schlistcont"
                  pills
                ></b-pagination>
              </div>
            </div>
            <div class="col-md-10 schlist" id="schlist" v-else>
              <div class="web-whitebg mb-5 web-dispflex wow web-fadeInUp">
                0 Results matched your search criteria
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
     
              <div class="vi-not-fount d-flex justify-content-center" v-else>
      <img
        src="../../assets/img/404.svg"
        alt="404"
        style="height:500px;padding:100px"
      />
    </div>
    </main>
     <vi-spinner
                v-if="viLoader"
                text="Loading..."
                textColor="vi-brand-color"
                class="flex-fill h-100 vi-fs12"
                style="width: 100%;min-height: 643px;z-index: 111;top:0;left:0;background-color: rgba(255, 255, 255, 0.5);border-radius: 1rem;"
              />
    
  </div>
</template>

<script>
import "vue-awesome/icons/restroom";
import "vue-awesome/icons/clinic-medical";
import "vue-awesome/icons/bed";
import "vue-awesome/icons/book";
import "vue-awesome/icons/laptop-code";
import "vue-awesome/icons/volleyball-ball";
import "vue-awesome/icons/utensils";
import "vue-awesome/icons/wheelchair";
import "vue-awesome/icons/bus-alt";
import "vue-awesome/icons/snowflake";
import "vue-awesome/icons/chalkboard-teacher";
import "vue-awesome/icons/concierge-bell";
import "vue-awesome/icons/shuttle-van";
import "vue-awesome/icons/bus";
import "vue-awesome/icons/satellite";
import "vue-awesome/icons/user-shield";
import "vue-awesome/icons/atom";
import "vue-awesome/icons/microscope";
import "vue-awesome/icons/server";
import "vue-awesome/icons/robot";
import "vue-awesome/icons/square-root-alt";
import "vue-awesome/icons/sort-alpha-down";
import "vue-awesome/icons/book-reader";
import "vue-awesome/icons/user-lock";
import "vue-awesome/icons/map-marker-alt";
import ViService from "@/services/ViService";
import ViSpinner from "../Common/ViSpinner.vue";

export default {
  name: "school-list",
  props: ["swapComponent"],
  data() {
    return {
      scList: null,
      viLoader: false,
      boardTypeArray: [
        "State Board",
        "STATE BOARD (till 10th)",
        "STATE BOARD (till 12th)",
        "CBSE",
        "ICSE",
        "ICSE & ISC",
        "IGCSE",
        "IGCSE & CIE",
        "CIE",
        "1B",
        "IB PYP ONLY",
        "IB PYP & MYP ONLY",
        "IB PYP MYP & DYP",
        "IB DP",
        "To be affiliated to CBSE",
        "To be affiliated to ISC/ICSE",
        "To be affiliated to STATE/OTHER BOARD",
        "To be affiliated to INTERNATIONAL BOARD",
      ],
      mediumList: [
        "English",
        "Tamil",
        "Telugu",
        "Urdu",
        "Sanskrit",
        "Kannada",
        "Malayalam",
        "Odia",
        "Punjabi",
        "Hindi",
        "Bengali",
        "Gujarati",
        "Assamese",
      ],
      facilitiesList: [
        "Co-Education",
        "Medical Facilities",
        "Hostel",
        "School Library",
        "Computer Lab",
        "Sports",
        "Cafeteria",
        "Disabled Friendly",
        "Transport Facilities",
        "AC Class rooms",
        "Smart Classrooms",
        "Restaurant",
        "Transport",
        "School bus",
        "Technology",
        "Anti Bulling",
        "Science Park",
        "Science Laboratory",
        "Computer Laboratory",
        "Robotics",
        "Math Laboratory",
        "English Laboratory",
        "Library",
        "Safety & Security",
      ],
      classList: [
        "Pre-Nursery",
        "Nursery",
        "LKG",
        "UKG",
        "KG",
        "Class 1",
        "Class 2",
        "Class 3",
        "Class 4",
        "Class 5",
        "Class 6",
        "Class 7",
        "Class 8",
        "Class 9",
        "Class 10",
        "Class 11",
        "Class 12"
      ],
      classesList: [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "Pre-school",
        "Nursery",
        "kindergarten",
      ],
      schoolTypeArray: [
        "Private",
        "Government",
        "Public Aided",
        "International",
        "National open school",
        "Special-needs",
        "Home school",
        "Distance education",
      ],
      checkedBoardType: [],
      checkedschoolType: [],
      checkedMediumType: [],
      checkedState: [],
      checkedFacilities: [],
      checkedDistrict: [],
      checkedClass: [],
      perPage: 15,
      currentPage: 1,
      stateList: [],
      cityList: [],
      allCityList: [],
      searchWords: "",
      stateName: "",
    };
  },
  created() {
    this.getStateList();
    //this.getDistrictList('Tamil Nadu', true);
  },
  methods: {
    viewMap(lat, lang) {
      if (lat && lang) {

        var ltde = Number(lat).toFixed(6);
        var lngt = Number(lang).toFixed(6);
        window.open(
          `https://maps.google.com/maps/search/?api=1&query=${ltde},${lngt}`
        );
      }
    },
    viewSchoolAction(item) {
      this.$store.dispatch("setSchoolInfo", item);
      window.open(
        `https://app--stage.vidhyaan.com/search-school/overview?schoolName=${item.accountName}&accId=${item.accountId}`
      );

    },

    async getStateList() {
      this.viLoader = true
      const response = await ViService.viGetOnly(`/common/fetchStateList`);
      
      if (response.isSuccess) {
        this.stateList = response.data;
        this.stateList.forEach((x) => {
          x["isSelected"] = false;
        });
      } else {
        this.$toasted.error(response.message);
         if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
      }
      this.viLoader = false
    },

    redirectRoute(vipath) {
      if (this.$route.path !== vipath) this.$router.push({ path: vipath });
    },

    fetchSearchedStates() {
      var input, filter, ul, li, a, i, txtValue;
      input = document.getElementById("myInput");
      filter = input.value.toUpperCase();
      ul = document.getElementById("myUL");
      li = ul.getElementsByTagName("li");
      for (i = 0; i < li.length; i++) {
        a = li[i].getElementsByTagName("label")[0];
        txtValue = a.textContent || a.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          li[i].style.display = "";
        } else {
          li[i].style.display = "none";
        }
      }
    },

    fetchSearchedCities() {
      var input, filter, ul, li, a, i, txtValue;
      input = document.getElementById("cityId");
      filter = input.value.toUpperCase();
      ul = document.getElementById("cityUL");
      li = ul.getElementsByTagName("li");
      for (i = 0; i < li.length; i++) {
        a = li[i].getElementsByTagName("label")[0];
        txtValue = a.textContent || a.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          li[i].style.display = "";
        } else {
          li[i].style.display = "none";
        }
      }
    },

    async getDistrictList(stateName, isSelected) {
     // this.checkedState = [];
      if (isSelected) {
        // if (stateName) {
        //   this.checkedState = stateName;
        // }

        const response = await ViService.viGetOnly(
          `/common/fetchDistrictList?stateName=${stateName}`
        );
        
        if (response.isSuccess) {
          this.cityList = response.data;
          this.cityList.forEach((x) => {
            (x["isSelected"] = false), (x["stateName"] = stateName);
          });
          this.allCityList = this.allCityList.concat(this.cityList);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      } else {
        this.checkedState = [];
        this.allCityList = this.allCityList.filter(
          (x) => x.stateName != stateName
        );
      }

      let selectStates = this.stateList.filter((x) => x.isSelected)

      for (let x of selectStates) {
          this.checkedState.push(x.state_name);
      }


    },
  },
  computed: {
    filteredSchool() {
      const letters = new Set();

      this.sList = this.$store.getters.schoolListObject;
      let filterSchoolList = this.sList;
     
      if (this.checkedBoardType.length > 0) {
        let cloneList = []
        for(let x of filterSchoolList){
          if(x.affilliateIdToBoard && x.affilliateIdToBoard.length > 0){
            for(let y of x.affilliateIdToBoard){
              if(this.checkedBoardType.includes(y)){
                letters.add(x)
              }

            }
          }
        }
        const allTermList = [];
        for (let x of letters) {
          allTermList.push(x);
        }

        filterSchoolList = allTermList
      }

      if(this.checkedschoolType.length > 0) {
        filterSchoolList = filterSchoolList.filter((s) =>
          this.checkedschoolType.includes(s.schoolType)
        );
      }
      
      if (this.checkedMediumType.length > 0) {

        let cloneList = []
        for(let x of filterSchoolList){
          if(x.medium && x.medium.length > 0){
            for(let y of x.medium){
              if(this.checkedMediumType.includes(y)){
                cloneList.push(x)
              }
            }
          }
        }
        filterSchoolList = cloneList
      }
      if (this.checkedFacilities.length > 0) {

        let cloneList = []
        for(let x of filterSchoolList){
          if(x.facilities && x.facilities.length > 0){
            for(let y of x.facilities){
              if(this.checkedFacilities.includes(y.name)){
                cloneList.push(x)
              }
            }
          }
        }
        filterSchoolList = cloneList
      }

      if (this.checkedState.length > 0) {
        filterSchoolList = filterSchoolList.filter((s) =>
          this.checkedState.includes(s.state)
        );
      }
      if (this.checkedDistrict.length > 0) {
        filterSchoolList = filterSchoolList.filter((s) =>
          this.checkedDistrict.includes(s.city)
        );
      }
      if(this.checkedClass.length > 0) {
        
        var classIndex = this.classList.indexOf(this.checkedClass[0]);
        var filteredFromClassList = this.classList.slice(classIndex);

        filterSchoolList = filterSchoolList.filter((s) =>
          filteredFromClassList.includes(s.gradeFrom)
        );

      }

      return filterSchoolList;
    },
    
  },
  filters: {
    removeURLPrefix: function(value) {
      if (!value) return "";
      value = value.trim();
      return value.replace(/(^\w+:|^)\/\//, "");
    },
    capitalize: function(value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
    },
  },
  components: {
    ViSpinner,
    "vi-header" : () => import("../../views/ViHeader.vue")

  }
};
</script>

<style lang="scss" scoped></style>
