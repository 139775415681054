<template>
  <div class="vi-school">
    <keep-alive>
      <component
        :is="currentComponent"
        :swap-component="swapComponent"
      ></component>
    </keep-alive>
  </div>
</template>

<script>
import ViSchoolList from "./ViSchoolList";
import ViSchoolOverview from "./ViSchoolOverview";
export default {
  name: "vi-school",
  components: {
    ViSchoolList,
    ViSchoolOverview,
  },
  data() {
    return {
      currentComponent: "ViSchoolList",
    };
  },
  methods: {
    swapComponent: function(component) {
      this.currentComponent = component;
    },
  },
};
</script>

<style lang="scss" scoped></style>
