<template>
  <div class="vi-school-overview">
    <main v-if="schoolData">
      <div class="dispflex brdcrumbscont text-center pl-3 pr-3" style="background: #E1E1E1;">
   
       <div >
         <ul class="breadcrumb" >
          <li><a href="#"><i class="fas fa-home"  @click="redirectRoute('/')"></i></a></li>
          <li><a href="javascript:void(0);" @click="goToSchoolList">School List</a></li>
          <li>School View</li>
        </ul>
       </div>
      </div>
      <!-- ======= Search Section ======= -->
      <div class="web-resfound p-2">
        <!-- <button class="btn web-backbtn" @click="swapComponent('ViSchoolList')">
          <v-icon name="arrow-left" />
          <span class="bck" style="display:none">Back</span>
        </button>-->
        <div class="container ">
          <div class="row">
            <div class="col-md-3">
              <img
                style="width:100%; height:160px"
                v-if="schoolData.image && schoolData.image.path"
                :src="schoolData.image.path"
                :alt="schoolData.accountName"
                :title="schoolData.accountName"
              />
              <img  v-else src="../../assets/img/noimage.png" alt="Avatar">
            </div>

            <div class="web-verified" v-if="schoolData.isVerified">
              <img
                src="../../assets/img/verified.png"
                alt="Vidhyaan web-verified"
                title="web-verified"
              />
            </div>

            <div class="col-md-9 web-schnamecont mt-md-3 mt-sm-3">
              <h4>
                <strong
                  >{{ schoolData.accountName }} </strong
                >
              </h4>
              <p>
                <span
                  ><i
                    class="fa fa-map-marker fa-lg"
                    aria-hidden="true"
                  ></i></span
                >{{ schoolData.street ? schoolData.street : '' }},{{ schoolData.city ? schoolData.city : '' }},{{ schoolData.district ? schoolData.district : '' }},{{ schoolData.state ? schoolData.state : '' }},{{ schoolData.pincode ? schoolData.pincode : '' }}
              </p>
              <p>
                <span><i class="fa fa-envelope" aria-hidden="true"></i></span
                ><a>{{ schoolData.email }}</a>
              </p>
              <p>
                <span
                  ><i class="fa fa-phone-square" aria-hidden="true"></i></span
                >+91 {{ schoolData.landlineNo }}
              </p>
              <div class="web-admbtn">
                <a
                  class="btn web-custbtn web-rounded-xl h-100 btn-3 "
                  style="width:225px"
                >
                  {{
                    checkAdmissionAuvaiable > 0
                      ? "Admission Open Now"
                      : "Admission Closed"
                  }}</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="web-filterbg pl-3 pr-3 m-0" id="filtersect">
        <div class="container-fluid">
          <div class="row">
            <div class="offset-md-3 web-mobnav">
              <nav class=" vertical-align-middle scroll ml-2">
                <ul
                  v-scroll-spy-active="{ class: 'tabactive' }"
                  v-scroll-spy-link
                >
                  <li><a>Overview</a></li>
                  <li><a>Seat Availability</a></li>
                  <li><a>Admission Process</a></li>
                  <li><a>Facilities</a></li>
                  <li><a>Contact Details</a></li>
                  <li><a>Gallery</a></li>
                  <li><a>Rating & Reviews</a></li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div class="web-innerpagecont py-3">
        <div class="container-fluid">
          <div class="row web-row-flex">
            <div class="col-md-3 col-sm-6">
              <p><strong>Place for Google ad</strong></p>
            </div>
            <div class="col-md-9 col-sm-6">
              <div v-scroll-spy="{ data: 'section', offset: 120 }">
                <div class="web-schovw_whitebg mb-5 wow web-fadeInUp">
                  <div class="web-headercont">
                    <div class="web-iconsection">
                      <img
                        src="../../assets/img/school_info_icon.svg"
                        width="99%"
                        alt="Overview"
                      />
                    </div>
                    <div class="web-titlesection">
                      <h1>Overview</h1>
                    </div>
                  </div>
                  <div class="p-3">
                    <p class="mt-2">
                      {{ schoolData.overView }}
                    </p>
                  </div>
                </div>
                <div class="web-schovw_whitebg mb-5 wow web-fadeInUp">
                  <div class="web-headercont">
                    <div class="web-iconsection">
                      <img
                        src="../../assets/img/seatsavailicn.svg"
                        width="99%"
                        alt="Seats Availability"
                        title="Seats Availability"
                      />
                    </div>
                    <div class="web-titlesection">
                      <h1>Seats Availability</h1>
                    </div>
                  </div>
                  <div class="p-3">
                    <table>
                      <thead>
                        <tr>
                          <th scope="col">Class Details</th>
                          <th scope="col">No. of Seats</th>
                          <th scope="col">No. of Seats Available</th>
                          <th scope="col">Admission Status</th>
                          <th scope="col">Starting Fee</th>
                          <th scope="col">Online</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(fee, index) in admissionDetails"
                          :key="index"
                        >
                          <td
                            v-if="fee.isShowSearch"
                            data-label="Class Details"
                          >
                            {{ fee.class ? fee.class.className : '' }}
                          </td>
                          <td v-if="fee.isShowSearch" data-label="No. of Seats">
                            {{ fee.noOfSeats }}
                          </td>
                          <td v-if="fee.isShowSearch" data-label="No. of Seats">
                            {{ fee.seatsAvailable }}
                          </td>
                          <td v-if="fee.isShowSearch" data-label="No. of Seats">
                            {{ fee.admissionStatus }}
                          </td>
                          <td v-if="fee.isShowSearch" data-label="Total Fees">
                            {{ fee.amount }}
                          </td>
                          <td v-if="fee.isShowSearch" data-label="Apply Now">
                            <a
                              href="javascript:void();"
                              class="web-applynow"
                              v-if="fee.admissionStatus == 'Open'"
                              @click.prevent="checkLoginUser"
                            >
                              <img
                                src="../../assets/img/handcursoricn.svg"
                                alt="apply now"
                                title="Apply Now"
                              />
                            </a>
                            <a
                              href="javascript:void();"
                              class="web-notapply"
                              v-else
                            >
                              <img
                                style="cursor: not-allowed"
                                src="../../assets/img/notapply.svg"
                                alt="Not Available"
                                title="Not Available"
                              />
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="web-schovw_whitebg mb-5 wow web-fadeInUp">
                  <div class="web-headercont">
                    <div class="web-iconsection" style="padding:6px">
                      <img
                        src="../../assets/img/admissionicn.svg"
                        width="99%"
                        alt="Vidhyaan Admission Process"
                        title="Vidhyaan Admission Process"
                      />
                    </div>
                    <div class="web-titlesection">
                      <h1>Admission Process</h1>
                    </div>
                  </div>
                  <div class="p-3">
                    <label class="web-custradio"
                      >Online Admission
                      <input
                        type="radio"
                        name="admission"
                        id="admon"
                        v-model="admissionSelect"
                        value="aOn"
                      />
                      <span class="web-checkmark"></span>
                    </label>
                    <label class="web-custradio"
                      >Offline Admission
                      <input
                        type="radio"
                        name="admission"
                        id="admoff"
                        v-model="admissionSelect"
                        value="aOff"
                      />
                      <span class="web-checkmark"></span>
                    </label>
                    <div
                      id="offlineadm"
                      v-show="admissionSelect === 'aOff'"
                      style="display:none;padding:8px;background:#efefef;"
                      class="mt-3"
                    >
                      Click on <a href="#contactdet">Contact School</a> for your
                      Offline admission.
                    </div>
                  </div>
                </div>
                <div class="web-schovw_whitebg mb-5 wow web-fadeInUp">
                  <div class="web-headercont">
                    <div class="web-iconsection" style="padding:6px">
                      <img
                        src="../../assets/img/facilitiesicn.svg"
                        width="99%"
                        alt="Facilities"
                        title="Facilities"
                      />
                    </div>
                    <div class="web-titlesection">
                      <h1>Facilities</h1>
                    </div>
                  </div>
                  <div class="p-3">
                    <p>
                      The school facility consists of not just the physical
                      structure such as plumbing, mechanical, electrical,
                      telecommunications, security, fire prevention and
                      suppression systems, and the various building systems, but
                      it also includes furnishing, materials and supplies,
                      equipment and information technology. Basic facilities we
                      have in our schools like,
                    </p>
                    <div class="web-dispflex">
                      <div class="web-colwidth mb-4 colcent">
                        <span class="web-facicn">
                          <img
                            class="img-fluid"
                            src="../../assets/img/school_icn.png"
                            width="56"
                          />
                        </span>
                        <span class="web-factxt">
                          <p>Type of School</p>
                          <span>{{ schoolData.schoolType }}</span>
                        </span>
                      </div>
                      <div class="web-colwidth mb-4 colcent">
                        <span class="web-facicn">
                          <img
                            class="img-fluid"
                            src="../../assets/img/afflilation_icn.png"
                            width="56"
                          />
                        </span>
                        <span class="web-factxt">
                          <p>Examination Board</p>
                          <span>{{ schoolData.affilliateIdToBoard | capitalize }}</span>
                        </span>
                      </div>
                      <!-- <div class="web-colwidth mb-4 colcent">
                        <span class="web-facicn">
                          <img
                            class="img-fluid"
                            src="../../assets/img/fees_icn.png"
                            width="56"
                          />
                        </span>
                        <span class="web-factxt">
                          <p>Annual Fees</p>
                          <span
                            >Start from Rs. {{ schoolData.lowestFees }}</span
                          >
                        </span>
                      </div> -->
                      <div class="web-colwidth mb-4 colcent">
                        <span class="web-facicn">
                          <img
                            class="img-fluid"
                            src="../../assets/img/lang_icn.png"
                            width="56"
                          />
                        </span>
                        <span class="web-factxt">
                          <p>Language of Instruction</p>
                          <span>{{ schoolData.medium }}</span>
                        </span>
                      </div>
                      <div
                        class="web-colwidth mb-4 colcent"
                        v-for="(fitem, index) in schoolData.facilities"
                        :key="index"
                      >
                        <span class="web-facicn">
                          <img
                            class="img-fluid"
                            :src="`${facilitiesURL}/${fitem.icon}.svg`"
                            width="56"
                          />
                        </span>
                        <span class="web-factxt">
                          <p>{{ fitem.name }}</p>
                          <span>Yes</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="web-schovw_whitebg mb-5 wow web-fadeInUp">
                  <div class="web-headercont">
                    <div class="web-iconsection" style="padding:6px">
                      <img
                        src="../../assets/img/rs.png"
                        width="99%"
                        alt="Fee Structure"
                        title="Fee Structure"
                      />
                    </div>
                    <div class="web-titlesection">
                      <h1>Fee Structure</h1>
                    </div>
                  </div>
                  <div class="p-3 web-feetable">
                    <table>
                      <thead>
                        <tr>
                          <th scope="col">Payment Type</th>
                          <th scope="col">Fees in [RS]</th>
                          <th scope="col" style="text-align:left">
                            Payment Status
                          </th>
                          <th scope="col" style="text-align:left">
                            Choose Option
                          </th>
                          <th scope="col">Print / Download</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td data-label="Payment Type">Full Term</td>
                          <td data-label="Fees in [RS]">12,000</td>
                          <td data-label="Payment Status" class="failed">
                            <i class="fas fa-circle"></i> Transaction Failed
                          </td>
                          <td data-label="Choose Option">
                            <label class="web-custcheckbox">
                              <input type="checkbox" />
                              <span class="web-checkmarkchk"></span>
                            </label>
                          </td>
                          <td data-label="Print / Download">
                            <button class="btn" style="padding:0" disabled>
                              <img
                                width="26"
                                src="../../assets/img/print_icon.svg"
                                alt="Print"
                                title="Print"
                              />
                            </button>
                            <button class="btn" style="padding:0" disabled>
                              <img
                                width="26"
                                src="../../assets/img/download_icon.svg"
                                alt="Download"
                                title="Download"
                              />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td data-label="Payment Type">First Term</td>
                          <td data-label="Fees in [RS]">5,000</td>
                          <td data-label="Payment Status" class="paid">
                            <i class="fas fa-circle"></i> Received
                          </td>
                          <td data-label="Choose Option">
                            <label class="web-custcheckbox">
                              <input type="checkbox" checked />
                              <span class="web-checkmarkchk"></span>
                            </label>
                          </td>
                          <td data-label="Print / Download">
                            <button class="btn" style="padding:0">
                              <img
                                width="26"
                                src="../../assets/img/print_icon.svg"
                                alt="Print"
                                title="Print"
                              />
                            </button>
                            <button class="btn" style="padding:0">
                              <img
                                width="26"
                                src="../../assets/img/download_icon.svg"
                                alt="Download"
                                title="Download"
                              />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td data-label="Payment Type">Second Term</td>
                          <td data-label="Fees in [RS]">5,000</td>
                          <td data-label="Payment Status" class="notpaid">
                            <i class="fas fa-circle"></i> Not Received
                          </td>
                          <td data-label="Choose Option">
                            <label class="web-custcheckbox">
                              <input type="checkbox" disabled />
                              <span class="web-checkmarkchk"></span>
                            </label>
                          </td>
                          <td data-label="Print / Download">
                            <button class="btn" style="padding:0" disabled>
                              <img
                                width="26"
                                src="../../assets/img/print_icon.svg"
                                alt="Print"
                                title="Print"
                              />
                            </button>
                            <button class="btn" style="padding:0" disabled>
                              <img
                                width="26"
                                src="../../assets/img/download_icon.svg"
                                alt="Download"
                                title="Download"
                              />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td data-label="Payment Type">Third Term</td>
                          <td data-label="Fees in [RS]">5,000</td>
                          <td data-label="Payment Status" class="notpaid">
                            <i class="fas fa-circle"></i> Not Received
                          </td>
                          <td data-label="Choose Option">
                            <label class="web-custcheckbox">
                              <input type="checkbox" disabled />
                              <span class="web-checkmarkchk"></span>
                            </label>
                          </td>
                          <td data-label="Print / Download">
                            <button class="btn" style="padding:0" disabled>
                              <img
                                width="26"
                                src="../../assets/img/print_icon.svg"
                                alt="Print"
                                title="Print"
                              />
                            </button>
                            <button class="btn" style="padding:0" disabled>
                              <img
                                width="26"
                                src="../../assets/img/download_icon.svg"
                                alt="Download"
                                title="Download"
                              />
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="col-md-12 text-right">
                      <button
                        type="button"
                        class="btn web-btnsml web-stwidth mt-3"
                        disabled
                      >
                        Proceed to Payment Gateway
                      </button>
                    </div>
                  </div>
                </div> -->
                <div class="web-schovw_whitebg mb-5 wow web-fadeInUp">
                  <div class="web-headercont">
                    <div class="web-iconsection" style="padding:10px">
                      <img
                        src="../../assets/img/contactdetailsicon.svg"
                        width="99%"
                        alt="Contact Details"
                        title="Contact Details"
                      />
                    </div>
                    <div class="web-titlesection">
                      <h1>Contact Details</h1>
                    </div>
                  </div>
                  <div class="p-3">
                    <div class="form-row">
                      <div
                        class="form-group input-group col-md-6 pr-md-3 mt-3"
                        v-if="schoolData.email"
                      >
                        <span class="web-has-float-label" style="flex-grow:0">
                          <label for="email">Email</label>
                        </span>
                        <div
                          style="padding-top:9px;width:100%;border-bottom:1px solid #e5e5e5"
                        >
                          <a href="mailto:info@velammal.org">{{
                            schoolData.email
                          }}</a>
                        </div>
                      </div>
                      <div
                        class="form-group input-group col-md-6 mt-3"
                        v-if="schoolData.landlineNo"
                      >
                        <span class="web-has-float-label" style="margin:1px">
                          <label for="phno">Phone #</label>
                        </span>
                        <div
                          style="padding-top:9px;width:100%;border-bottom:1px solid #e5e5e5"
                        >
                          +91 {{ schoolData.landlineNo }}
                        </div>
                      </div>
                      <div
                        class="form-group input-group col-md-6 pr-md-3 mt-3"
                        v-if="schoolData.website"
                      >
                        <span class="web-has-float-label" style="flex-grow:0">
                          <label for="website">Website</label>
                        </span>
                        <div
                          style="padding-top:9px;width:100%;border-bottom:1px solid #e5e5e5"
                        >
                          <a :href="schoolData.website" target="_blank">{{
                            schoolData.website | removeURLPrefix
                          }}</a>
                        </div>
                      </div>
                      <div
                        class="form-group input-group col-md-6 mt-3"
                        v-if="schoolData.location"
                      >
                        <span class="web-has-float-label" style="margin:1px">
                          <label for="location">Location</label>
                        </span>
                        <div
                          style="padding-top:9px;width:100%;border-bottom:1px solid #e5e5e5"
                        >
                          {{ schoolData.location }}
                        </div>
                      </div>
                      <div
                        class="form-group input-group col-md-6 mt-3"
                        v-if="schoolData.address"
                      >
                        <span class="web-has-float-label" style="margin:1px">
                          <label for="location">Location</label>
                        </span>
                        <div
                          style="padding-top:9px;width:100%;border-bottom:1px solid #e5e5e5"
                        >
                          {{ schoolData.address }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="web-schovw_whitebg mb-5 wow web-fadeInUp">
                  <div class="web-headercont">
                    <div class="web-iconsection" style="padding:10px">
                      <img
                        src="../../assets/img/contactdetailsicon.svg"
                        width="99%"
                        alt="Contact Details"
                        title="Contact Details"
                      />
                    </div>
                    <div class="web-titlesection">
                      <h1>Gallery</h1>
                    </div>
                  </div>
                  <div class="p-3">
                    <div class="row">
                      <div
                        style="background-color: darkgrey;margin-left: 1%;"
                        class="col-lg-2"
                        v-for="(item, index) in galleryList"
                        :key="index"
                      >
                        <img
                          :src="item.path"
                          alt="Avatar"
                          class="mt-3"
                          width="150"
                          height="150"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="web-schovw_whitebg mb-5 wow web-fadeInUp">
                  <div class="web-headercont">
                    <div class="web-iconsection" style="padding:10px">
                      <img
                        src="../../assets/img/reviewicn.svg"
                        width="99%"
                        alt="Ratings & Reviews"
                        title="Ratings & Reviews"
                      />
                    </div>
                    <div class="web-titlesection">
                      <h1>Ratings & Reviews</h1>
                    </div>
                  </div>
                  <div class="p-3">
                    <div class="web-dispflex rating">
                      <div class="web-colwidth">
                        <p><strong>Review by Parents</strong></p>
                        <hr />
                        <div class="web-dispflex">
                          <div>
                            <p>
                              <label>Infrastructure: </label>
                              <star-rating
                                :star-size="20"
                                :show-rating="false"
                                inactive-color="#666"
                                active-color="#ffa500"
                                :increment="1"
                                :rating="schoolData.ratings.infrastructure"
                                :read-only="true"
                                class="vi-r-review"
                              />
                            </p>
                            <p>
                              <label>Academics: </label>
                              <star-rating
                                :star-size="20"
                                :show-rating="false"
                                inactive-color="#666"
                                active-color="#ffa500"
                                :increment="0.1"
                                :rating="schoolData.ratings.academics"
                                :read-only="true"
                                class="vi-r-review"
                              />
                            </p>
                            <p>
                              <label>Sports: </label>
                              <star-rating
                                :star-size="20"
                                :show-rating="false"
                                inactive-color="#666"
                                active-color="#ffa500"
                                :increment="0.1"
                                :rating="schoolData.ratings.sports"
                                :read-only="true"
                                class="vi-r-review"
                              />
                            </p>
                            <p>
                              <label>Faculty: </label>
                              <star-rating
                                :star-size="20"
                                :show-rating="false"
                                inactive-color="#666"
                                active-color="#ffa500"
                                :increment="0.1"
                                :rating="schoolData.ratings.faculty"
                                :read-only="true"
                                class="vi-r-review"
                              />
                            </p>
                            <p>
                              <label>Safety: </label>
                              <star-rating
                                :star-size="20"
                                :show-rating="false"
                                inactive-color="#666"
                                active-color="#ffa500"
                                :increment="0.1"
                                :rating="schoolData.ratings.safety"
                                :read-only="true"
                                class="vi-r-review"
                              />
                            </p>
                          </div>
                          <div class="web-overall">
                            <p>{{ schoolData.ratings | averageRating }}</p>
                            <p>
                              <star-rating
                                :star-size="20"
                                :show-rating="false"
                                inactive-color="#666"
                                active-color="#ffa500"
                                :increment="0.5"
                                :rating="schoolData.ratings | averageRating"
                                :read-only="true"
                                class="vi-r-review"
                              />
                            </p>
                            <p>(Over All)</p>
                          </div>
                        </div>
                      </div>
                      <!-- <div class="web-colwidth">
                        <p><strong>Review by Vidhyaan</strong></p>
                        <hr />
                        <div class="web-dispflex">
                          <div>
                            <p>
                              <label>Infrastructure: </label>
                              <star-rating
                                :star-size="20"
                                :show-rating="false"
                                inactive-color="#666"
                                active-color="#ffa500"
                                :increment="0.1"
                                :read-only="true"
                                class="vi-r-review"
                              />
                            </p>
                            <p>
                              <label>Academics: </label>
                              <star-rating
                                :star-size="20"
                                :show-rating="false"
                                inactive-color="#666"
                                active-color="#ffa500"
                                :increment="0.1"
                                :read-only="true"
                                class="vi-r-review"
                              />
                            </p>
                            <p>
                              <label>Sports: </label>
                              <star-rating
                                :star-size="20"
                                :show-rating="false"
                                inactive-color="#666"
                                active-color="#ffa500"
                                :increment="0.1"
                                :read-only="true"
                                class="vi-r-review"
                              />
                            </p>
                            <p>
                              <label>Faculty: </label>
                              <star-rating
                                :star-size="20"
                                :show-rating="false"
                                inactive-color="#666"
                                active-color="#ffa500"
                                :increment="0.1"
                                :read-only="true"
                                class="vi-r-review"
                              />
                            </p>
                            <p>
                              <label>Safety: </label>
                              <star-rating
                                :star-size="20"
                                :show-rating="false"
                                inactive-color="#666"
                                active-color="#ffa500"
                                :increment="0.1"
                                :read-only="true"
                                class="vi-r-review"
                              />
                            </p>
                          </div>
                          <div class="web-overall">
                            <p>4.0</p>
                            <p>
                              <star-rating
                                :star-size="20"
                                :show-rating="false"
                                inactive-color="#666"
                                active-color="#ffa500"
                                :increment="0.1"
                                :read-only="true"
                                class="vi-r-review"
                              />
                            </p>
                            <p>(Over All)</p>
                          </div>
                        </div>
                      </div> -->
                    </div>
                    <div class="web-dispflex mt-3">
                      <div class="web-colwidth rating" style="border:0">
                        <h3>Write a Review</h3>
                        <span class="vi-error" v-if="rateError"
                          >Please rate all catocary</span
                        >
                        <!-- <p>
                          <label>Over All: </label>
                          <star-rating
                            :star-size="20"
                            inactive-color="#666"
                            active-color="#ffa500"
                            :increment="0.5"
                            class="vi-w-review"
                          />
                        </p> -->
                        <p>
                          <label>Infrastructure: </label>
                          <star-rating
                            :star-size="20"
                            inactive-color="#666"
                            active-color="#ffa500"
                            :increment="0.5"
                            @rating-selected="
                              setRating($event, 'infrastructure')
                            "
                            class="vi-w-review"
                            v-model="infrastructure"
                          />
                        </p>
                        <p>
                          <label>Academics: </label>
                          <star-rating
                            :star-size="20"
                            inactive-color="#666"
                            active-color="#ffa500"
                            :increment="0.5"
                            @rating-selected="setRating($event, 'academics')"
                            class="vi-w-review"
                            v-model="academics"
                          />
                        </p>
                        <p>
                          <label>Sports: </label>
                          <star-rating
                            :star-size="20"
                            inactive-color="#666"
                            active-color="#ffa500"
                            :increment="0.5"
                            @rating-selected="setRating($event, 'sports')"
                            class="vi-w-review"
                            v-model="sports"
                          />
                        </p>
                        <p>
                          <label>Faculty: </label>
                          <star-rating
                            :star-size="20"
                            inactive-color="#666"
                            active-color="#ffa500"
                            :increment="0.5"
                            @rating-selected="setRating($event, 'faculty')"
                            class="vi-w-review"
                            v-model="faculty"
                          />
                        </p>
                        <p>
                          <label>Safety: </label>
                          <star-rating
                            :star-size="20"
                            inactive-color="#666"
                            active-color="#ffa500"
                            :increment="0.5"
                            @rating-selected="setRating($event, 'safety')"
                            class="vi-w-review"
                            v-model="safety"
                          />
                        </p>
                      </div>
                      <div class="web-colwidth" style="border:0">
                        <div class="form-row mt-4">
                          <div class="form-group input-group mt-md-3">
                            <span class="web-has-float-label">
                              <textarea
                                class="form-control web-form-input"
                                style="min-height:135px;resize:none"
                                id="message"
                                placeholder="Message"
                                v-model="message"
                              ></textarea>
                              <label
                                v-if="messageError"
                                class="vi-error"
                                for="message"
                                >{{ messageError }}</label
                              >
                              <label for="message" v-else
                                >Message<span class="required">*</span></label
                              >
                            </span>
                          </div>
                        </div>
                        <button
                          type="submit"
                          class="web-custbutton mt-3"
                          @click="submitRating"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <vi-spinner
      v-else
      text="Loading..."
      textColor="vi-brand-color"
      class="flex-fill h-100 vi-fs12"
      style="width: 100%;z-index: 111;top:0;left:0;background-color: #fff;bottom:0;right:0;"
    />
  </div>
</template>

<script>
import "vue-awesome/icons/arrow-left";
import ViSpinner from "../Common/ViSpinner";
import ViService from "@/services/ViService";
import errorLog from "@/utils/errorLog";
import secureUI from "../../utils/secureUI";
import StarRating from "vue-star-rating";
export default {
  name: "vi-school-overview",
  components: {
    ViSpinner,
    StarRating,
  },
  props: ["swapComponent"],
  data() {
    return {
      section: 0,
      viLoader: true,
      schoolData: null,
      admissionSelect: "aOn",
      infrastructure: null,
      academics: null,
      sports: null,
      faculty: null,
      safety: null,
      message: null,
      messageError: null,
      rateError: null,
      galleryList: [],
      facilitiesURL: process.env.BASE_URL + "facilities",
      admissionDetails: [],
    };
  },
  computed: {
    schoolItem() {
      return this.$store.getters.SchoolInfoObject;
    },
    checkAdmissionAuvaiable() {
      if (this.admissionDetails.length > 0) {
        const statusCheck = this.admissionDetails.filter(
          (x) => x.admissionStatus == "Open" && x.isShowSearch
        );
        return statusCheck.length;
      } else {
        return 0;
      }
    },
  },
  methods: {
    getSchoolInfo() {
      ViService.viGetOnly("/common/details?accountId=" + this.schoolItem._id)
        .then((res) => {
          if (res.isSuccess) {
            let data = secureUI.secureGet(res.data);
            if (res.data) {
              this.schoolData = data;
              this.searchSchoolDetails();
            }
          } else {
            this.$toasted.show(res.message);
          }
        })
        .catch((e) => {
          let eData = errorLog.apiErrorLog(e);
          this.$toasted.error(eData);
          console.log(eData);
        });
    },
    async schoolGallaryList() {
      const response = await ViService.viGetOnly(
        "/common/galleryList?accountId=" + this.schoolItem._id
      );

      //const response = await ViService.viXGet(`/account/galleryList?accountId=${userData.userDetails.account._id}`,userData.token);


      if (response.isSuccess) {
        const details = secureUI.secureGet(response.data);
        this.galleryList = details.gallery || [];
      } else {
        this.$toasted.error(response.message);
      }
    },
    searchSchoolDetails() {
      ViService.viGetOnly(
        "/common/getSearchSchoolFeesDetail?accountId=" + this.schoolData._id
      )
        .then((res) => {
          if (res.isSuccess) {
            let data = secureUI.secureGet(res.data);
            if (res.data) {
              this.admissionDetails = data;
            }
          } else {
            this.$toasted.show(res.message);
          }
        })
        .catch((e) => {
          let eData = errorLog.apiErrorLog(e);
          this.$toasted.error(eData);
          console.log(eData);
        });
    },
    setRating(rating, value) {
      value == "infrastructure" ? (this.infrastructure = rating) : null;
      value == "academics" ? (this.academics = rating) : null;
      value == "sports" ? (this.sports = rating) : null;
      value == "faculty" ? (this.faculty = rating) : null;
      value == "safety" ? (this.safety = rating) : null;
    },
    submitRating() {
      let userData = secureUI.sessionGet("user");
      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        this.$toasted.clear();
        //let message = Validation("message", this.message);
        //this.messageError = message;
        if (
          !this.infrastructure ||
          !this.academics ||
          !this.sports ||
          !this.faculty ||
          !this.safety
        ) {
          this.rateError = true;
        } else {
          this.rateError = null;
        }
        if (
          !message &&
          this.infrastructure &&
          this.academics &&
          this.sports &&
          this.faculty &&
          this.safety
        ) {
          let requestBody = {
            accountId: this.schoolItem._id,
            parentId: userData.userDetails._id,
            infrastructure: this.infrastructure,
            academics: this.academics,
            sports: this.sports,
            faculty: this.faculty,
            safety: this.safety,
            message: this.message,
            schoolName: "",
            category: "",
            city: "",
          };
          
          this.viLoader = true;
          ViService.viXPost("/rating/school", requestBody, userData.token)
            .then((res) => {
              this.viLoader = null;
              if (res.isSuccess) {
                this.clearMsg();
                this.$toasted.success("Rating submitted successfully");
              } else {
                this.$toasted.error(res.message);
              }
            })
            .catch((e) => {
              this.viLoader = null;
              let eData = errorLog.apiErrorLog(e);
              this.$toasted.error(eData);
              console.log(eData);
            });
        }
      }
    },
    clearMsg() {
      this.infrastructure = 0;
      this.academics = 0;
      this.sports = 0;
      this.faculty = 0;
      this.safety = 0;
      this.message = null;
    },
    checkLoginUser() {
      this.$toasted.success("Please login...");
      this.$router.push("/login");
    },
    redirectRoute(vipath) {
      if (this.$route.path !== vipath) this.$router.push({ path: vipath });
    },
    goToSchoolList() {
      this.$store.dispatch("setSchoolInfo", {});
      this.swapComponent("ViSchoolList");
    }
  },
  activated() {
    this.getSchoolInfo();
    this.schoolGallaryList();
  },
  deactivated() {
    this.schoolData = null;
  },
  filters: {
    averageRating: function(value) {
      if (!value) return "";
      // const length = Object.keys(value).length;
      let totalSum = 0;
      for (let key in value) {
        if (value.hasOwnProperty(key)) {
          totalSum += value[key];
        }
      }
      let val = (totalSum / 5).toFixed(1);
      return parseFloat(val);
    },
    removeURLPrefix: function(value) {
      if (!value) return "";
      value = value.trim();
      return value.replace(/(^\w+:|^)\/\//, "");
    },
  },
};
</script>

<style lang="scss" scoped>
.vi-r-review,
.vi-w-review {
  display: inline-block;
}
</style>
